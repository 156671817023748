import SvgAppStore from 'assets/footer/app-store.inline.svg';
import SvgEmail from 'assets/footer/contact-email.inline.svg';
import SvgPhone from 'assets/footer/contact-phone.inline.svg';
import SvgWebsite from 'assets/footer/contact-website.inline.svg';
import SvgFb from 'assets/footer/fb.inline.svg';
import SvgGooglePlay from 'assets/footer/google-play.inline.svg';
import SvgIn from 'assets/footer/in.inline.svg';
import SvgTwitter from 'assets/footer/twitter.inline.svg';
import { routes } from 'constants/routes';
import { Link } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useLanguage from 'hooks/useLanguage';
import React from 'react';

export const PrivacyPolicy = () => {
	const { language } = useLanguage();
	const { t } = useTranslation();
	
	const to = `${process.env.GATSBY_SITE_URL}${routes[language].PRIVACY_POLICY}`;
	return <Link to={to} target="_blank" rel="noreferrer">{t('footerLP.privacyPolicy')}</Link>;
};

export const StoreSection = ({ ...props }) => (
	<div {...props}>
		<a tw="w-48/100 max-w-80 sm:max-w-80 mr-12" href="https://apps.apple.com/pl/app/finteq/id1568493252" target="_blank" rel="noreferrer">
			<SvgAppStore width="100%" height="100%"/>
		</a>
		<a tw="w-48/100 max-w-80 sm:max-w-80" href="https://play.google.com/store/apps/details?id=pl.finteq" target="_blank" rel="noreferrer">
			<SvgGooglePlay width="100%" height="100%"/>
		</a>
	</div>
);

export const MobileFooter = () => {
	const { t } = useTranslation();
	const { language } = useLanguage();

	return (
		<div className="sm:flex sm:items-end sm:justify-between mx-20">
			<div className="sm:mt-15 mb-05 mx-auto">
				<ul className="ul-custom-triangle text-11 sm:text-10 grid sm:grid-cols-3 sm:gap-x-50 2xl:gap-x-150 mb-0">
					<li>
						<Link to={routes[language].DASHBOARD}>{t('footer.ul.li.1')}</Link>
					</li>
					<li>
						<Link to={routes[language].BECOME_AN_ANIMATOR}>{t('footer.ul.li.2')}</Link>
					</li>
					<li className="mb-15 sm:mb-0">
						<a className="whitespace-nowrap" href="https://finteq.pl/logowanie" target="_blank" rel="noreferrer">{t('footer.ul.li.3')}</a>
					</li>
					<li>
						<Link to={routes[language].PRICING}>{t('footer.ul.li.4')}</Link>
					</li>
					<li>
						<p>
							<Link to={routes[language].FOR_SUPPLIER}>{t('footer.ul.li.5')}</Link>
						</p>
					</li>
					<li className="mb-15 sm:mb-0">
						<Link to={routes[language].CONTACT}>{t('footer.ul.li.6')}</Link>
					</li>
					<li>
						<PrivacyPolicy/>
					</li>
					<li>
						<p>{t('footer.ul.li.8')}</p>
					</li>
				</ul>

				<div className="sm:flex sm:items-end sm:justify-between sm:mr-28">
					<div/>
					<div className="flex items-center justify-center mt-20 xl:mt-0 mb-10 mb-20">
						<a href="https://twitter.com/finteq_pl" target="_blank" rel="noreferrer"><SvgTwitter tw="mx-07"/></a>
						<p><SvgFb tw="mx-07"/></p>
						<a href="https://www.linkedin.com/company/fq1" target="_blank" rel="noreferrer"><SvgIn tw="mx-07"/></a>
					</div>
				</div>
			</div>
		</div>
	);
};

function ContactSection({ isTruckerPage }) {
	const url = process.env.GATSBY_SITE_URL;

	return (
		<div className="my-05">
			<div className="flex items-center mt-10">
				<SvgEmail tw="ml-02 mr-03"/>
				{isTruckerPage && <a href="tel:602 300 976" className="text-12 ml-10">602 300 976, 572 004 263</a>}
				{!isTruckerPage && <a href="tel:780 780 216" className="text-12 ml-10">780 780 216, 780 780 154</a>}
			</div>
			<div className="flex items-center mt-10">
				<SvgPhone/>
				<a href="mailto:hello@finteq.pl" className="text-12 ml-10">hello@finteq.pl</a>
			</div>
			<div className="flex items-center mt-10">
				<SvgWebsite/>
				<a href={url} className="text-12 ml-10">{url?.substr(8, url?.length)}</a>
			</div>
		</div>
	);
}

export default function FooterLP({ isTruckerPage }) {
	const { t } = useTranslation();

	return (
		<footer className="mt-10 pt-10 border-t border-solid border-grey-E">
			{/* mobile */}
			<div className="sm:hidden">
				<MobileFooter />
			</div>

			{/* desktop */}
			<div className="hidden sm:block layout">
				<div className="sm:flex sm:items-end sm:justify-between">
					<ContactSection isTruckerPage={isTruckerPage} />

					<div className="mt-20">
						<StoreSection tw="flex items-center justify-start sm:justify-end"/>

						<p className="sm:text-right text-grey-A text-09 sm:text-08 mt-10 sm:mt-05 -mb-05">{t('footerLP.trademark.appStore')}</p>
						<p className="sm:text-right text-grey-A text-09 sm:text-08">{t('footerLP.trademark.googlePlay')}</p>
					</div>
				</div>

				<div className="sm:flex sm:items-end sm:justify-between">
					<p className="hidden sm:block text-grey-A mb-10 sm:mb-20"><PrivacyPolicy/></p>
					<p className="hidden sm:block text-grey-A mb-10 sm:mb-20">{t('footerLP.rightsReserved')}</p>
					<div className="flex items-center justify-center mt-20 mb-10 sm:mb-20">
						<a href="https://twitter.com/finteq_pl" target="_blank" rel="noreferrer"><SvgTwitter tw="mx-07"/></a>
						<div><SvgFb tw="mx-07"/></div>
						<a href="https://www.linkedin.com/company/fq1" target="_blank" rel="noreferrer"><SvgIn tw="mx-07"/></a>
					</div>
				</div>
			</div>

			{/*<div className="sm:hidden bg-grey-E border-t border-solid border-grey-D text-11 text-grey-6">*/}
			{/*	<div className="layout">*/}
			{/*		<p className="my-15">*/}
			{/*			<Link to={routes[language].DASHBOARD}>{t('footerLP.ulMobile.li.1')}</Link>*/}
			{/*		</p>*/}
			{/*		<p className="my-15">*/}
			{/*			<Link to={routes[language].BECOME_AN_ANIMATOR}>{t('footerLP.ulMobile.li.2')}</Link>*/}
			{/*		</p>*/}
			{/*		<p className="my-15">*/}
			{/*			<Link to={routes[language].FOR_SUPPLIER}>{t('footerLP.ulMobile.li.3')}</Link>*/}
			{/*		</p>*/}
			{/*		<p className="my-15">{t('footerLP.ulMobile.li.4')}</p>*/}
			{/*		<p className="my-15">*/}
			{/*			<a href="https://finteq.pl/logowanie" target="_blank" rel="noreferrer">{t('footerLP.ulMobile.li.5')}</a>*/}
			{/*		</p>*/}
			{/*		<p className="pb-15"><PrivacyPolicy/></p>*/}
			{/*	</div>*/}
			{/*</div>*/}

			{/*<div className="sm:hidden flex items-center justify-center py-18">*/}
			{/*	<p className="text-grey-A">{t('footerLP.rightsReserved')}</p>*/}
			{/*</div>*/}
		</footer>
	);
}
